import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { VikingDrakkarDark } from '@illustrations';

const SectionDrakkarContainer = styled(Container)`
  flex-direction: column-reverse;
  align-items: center;
  overflow: hidden;
  margin-bottom: ${spaceMb(5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(10)};
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SectionDrakkarVector = styled(VikingDrakkarDark)`
  width: ${toVW(296, 'mobile')};
  height: ${toVW(298, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(558, 'desktop')};
    height: ${toVW(564, 'desktop')};
    margin-right: ${spaceDt(-3.5)};
  }
`;

const SectionDrakkarContent = styled.h3`
  ${getTypography('heading-3')};

  ${screenMin('lg')} {
    margin-left: ${spaceDt(26)};
    width: ${toVW(599, 'desktop')};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(10)};
  }
`;

export { SectionDrakkarContainer, SectionDrakkarVector, SectionDrakkarContent };
