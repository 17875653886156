import React from 'react';
import { TextEffect } from '@fragments';
import { BookingSync, Heyjobs, Vehiculum } from '@logos';
import { color } from '@helpers/styles';
import { StyledFoodsi } from '../Advisory.styled';

const dataAdvisory = {
  sectionHeader: {
    name: 'Advisory',
    headline: (
      <>
        <TextEffect effect="highlight">Advisory</TextEffect> for startups
      </>
    ),
    content: `Ragnarson is not only about software (and cash).
      We provide our customers and investments with top-notch consulting services.`,
    linkArray: [
      {
        to: '/fund',
        label: 'Ragnarson Fund',
        title: 'Go to Ragnarson Fund',
      },
      {
        to: '/software-development',
        label: 'Software development',
        title: 'Go to Software development',
      },
    ],
  },
  sectionDrakkar: {
    content: (
      <>
        <TextEffect effect="highlight">If you need</TextEffect>our help, contact us via the form below. We will schedule
        an introductory call with you.
      </>
    ),
  },
  sectionTeams: {
    review: {
      headline: 'Ragnarson’s well-structured, agile workflow is by far the best I’ve experienced.',
      content: `The team created a seamless experience by adapting their workflows
        to our processes and routines. We use several tools for sprint planning
        and tracking - primarily Jira. They have access to our internal project management
        tools like our task tracker and coding repositories. We also use Slack for communication.`,
      author: { name: 'Boris Diebold, CTO', to: 'https://www.linkedin.com/in/borisdiebold/' },
      avatarImgName: 'heyjobs-app',
    },
    block: {
      title: 'Remote teams',
      content: `We have been a remote-first company for 15 years.
        This came with a lot of challenges that we learned how to solve.
        Now, we pass this knowledge on to tech teams so that they reach a broader
        pool of talents and become more resilient.`,
      // TODO: How to apply proper svg with a proper size through CMS?
      vector: (
        <Heyjobs
          config={{
            widthMb: 100,
            width: 227,
            fill: color.text.secondary,
          }}
        />
      ),
    },
  },
  sectionManagement: {
    block: {
      title: 'Transparent management',
      content: `Remote means trust. Trust means transparency.
        Transparent management is the ability to share every decision and number.
        It might seem impossible, but we will show you how to get there step-by-step.`,
    },
  },
  sectionFunding: {
    block: {
      title: 'Funding',
      content: `While running a software agency for startups, we made a lot of VC friends.
        We know what they look for in startups (well, we run a fund as well).`,
      vector: (
        <StyledFoodsi
          config={{
            widthMb: 100,
            width: 200,
            fill: color.text.secondary,
          }}
        />
      ),
    },
    review: {
      headline: 'A network of investors',
      content: `We were exploring seed funding opportunities to boost the
        development of our food-waste mobile application and speed up time to market.
        Ragnarson introduced us to a number of investors from their network.
        We held valuable meetings, gathered feedback,
        and are still in promising dialogue with some of them.`,
      author: { name: 'Mateusz Kowalczyk, CEO', to: 'https://www.linkedin.com/in/mkowalczykk/' },
      avatarImgName: 'foodsi',
    },
  },
  sectionTech: {
    block: {
      title: 'Tech recruitment',
      content: `Building a tech team is tough, but possible. When the time comes to form your own team,
      we will prepare the hand-over of the project and verify the skills of your on-site candidates
      based on the technological needs of your project`,
    },
    vectorArray: [
      <Vehiculum
        key="0"
        config={{
          widthMb: 100,
          width: 227,
          fill: color.text.secondary,
        }}
      />,
      <Heyjobs
        key="1"
        config={{
          widthMb: 70,
          width: 227,
          fill: color.text.secondary,
        }}
      />,
      <BookingSync
        key="2"
        config={{
          widthMb: 100,
          width: 227,
          fill: color.text.secondary,
        }}
      />,
    ],
  },
};

export default dataAdvisory;
