import React from 'react';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import { SectionContainer } from '../Advisory.styled';
import dataAdvisory from '../data/Advisory.data';

const {
  sectionManagement: { block },
} = dataAdvisory;

const SectionManagement = () => (
  <StringsWrapper>
    {useStateScreenMobile() ? (
      <Strings name="advisory-management--mobile" height={94} top={75} mobile />
    ) : (
      <Strings name="advisory-management" height={575} top={0} />
    )}
    <SectionContainer>
      <ContentBlock headline={block.title} content={block.content} />
    </SectionContainer>
  </StringsWrapper>
);

export default SectionManagement;
