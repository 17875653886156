import React from 'react';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import { SectionContainer, Row, LogoItem } from '../Advisory.styled';
import dataAdvisory from '../data/Advisory.data';

const {
  sectionTech: { block, vectorArray },
} = dataAdvisory;

const SectionTech = () => (
  <StringsWrapper>
    {!useStateScreenMobile() && <Strings name="advisory-tech" height={519} top={-220} />}
    <SectionContainer columned>
      <ContentBlock headline={block.title} content={block.content} />
      <Row>
        {vectorArray.map((vector, index) => (
          <LogoItem key={index}>{vector}</LogoItem>
        ))}
      </Row>
    </SectionContainer>
  </StringsWrapper>
);

export default SectionTech;
