import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import dataAtribute from '../data/Advisory.data';
import { ContentWrapper, ReviewManagement } from './SectionFunding.styled';
import { SectionContainer, Row } from '../Advisory.styled';

const {
  sectionFunding: { review, block },
} = dataAtribute;

const SectionFunding = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "caseStudy/reviews" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 200, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF], quality: 50)
            id
          }
          name
        }
      }
    }
  `);

  const imageData = useMemo(() => nodes.filter(({ name }) => name === review.avatarImgName)[0], [nodes]);

  return (
    <StringsWrapper>
      {useStateScreenMobile() && <Strings name="advisory-funding--mobile" height={296} top={0} mobile />}
      <SectionContainer expanded>
        <ContentWrapper>
          <ContentBlock headline={block.title} content={block.content} />
          <Row>{block.vector}</Row>
        </ContentWrapper>

        <ReviewManagement
          headline={review.headline}
          content={review.content}
          author={review.author}
          imageData={imageData}
        />
      </SectionContainer>
    </StringsWrapper>
  );
};

export default SectionFunding;
