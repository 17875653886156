import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LinkExternal } from '@fragments';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  CardReviewContainer,
  CardReviewHeadline,
  CardReviewContent,
  CardReviewFooter,
  CardReviewPicture,
} from './CardReview.styled';

export const CardReview = ({ headline, content, author: { to, name }, imageData, className }) => {
  const image = useMemo(() => getImage(imageData), [imageData]);

  return (
    <CardReviewContainer className={className}>
      <CardReviewHeadline>{headline}</CardReviewHeadline>
      <CardReviewContent>{content}</CardReviewContent>
      <CardReviewFooter>
        <CardReviewPicture>
          <GatsbyImage image={image} alt={`${imageData.name} representative`} />
        </CardReviewPicture>
        <LinkExternal to={to}>{name}</LinkExternal>
      </CardReviewFooter>
    </CardReviewContainer>
  );
};

CardReview.propTypes = {
  headline: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  author: PropTypes.exact({
    name: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  imageData: PropTypes.shape({
    name: PropTypes.string,
    /* eslint-disable-next-line react/forbid-prop-types */
    childImageSharp: PropTypes.any,
  }).isRequired,
  className: PropTypes.string,
};

CardReview.defaultProps = {
  className: null,
};
