import React from 'react';
import { PageHeader, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import dataAdvisory from '../data/Advisory.data';

const {
  sectionHeader: { name, headline, content, linkArray },
} = dataAdvisory;

const SectionHeader = () => (
  <StringsWrapper>
    {useStateScreenMobile() && <Strings name="advisory-header--mobile" height={860} top={0} mobile />}
    <PageHeader id="test" name={name} headline={headline} content={content} linkArray={linkArray} />
  </StringsWrapper>
);

export default SectionHeader;
