import React from 'react';
import { SEO } from '@fragments';
import SectionHeader from './sections/SectionHeader';
import SectionDrakkar from './sections/SectionDrakkar';
import SectionRemoteTeams from './sections/SectionRemoteTeams';
import SectionFunding from './sections/SectionFunding';
import SectionManagement from './sections/SectionManagement';
import SectionTech from './sections/SectionTech';
import SectionContactForm from './sections/SectionContactForm';

const Advisory = () => (
  <>
    <SEO
      title="Startup consulting services - startup advisory | Ragnarson"
      description="Based on fifteen years of experience working with Tech Startups,
      we advise our clients and investments regarding recruitment, team building, remote work and funding."
    />

    <SectionHeader />

    <SectionRemoteTeams />

    <SectionManagement />

    <SectionFunding />

    <SectionTech />

    <SectionDrakkar />

    <SectionContactForm />
  </>
);

export default Advisory;
