import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ContentBlock, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import dataAtribute from '../data/Advisory.data';

import { SectionContainer, BlockContainer, AdvisoryReview, Row } from '../Advisory.styled';

const {
  sectionTeams: { review, block },
} = dataAtribute;

const SectionTeams = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "caseStudy/reviews" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 200, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF], quality: 50)
            id
          }
          name
        }
      }
    }
  `);

  const imageData = useMemo(() => nodes.filter(({ name }) => name === review.avatarImgName)[0], [nodes]);

  return (
    <StringsWrapper>
      {!useStateScreenMobile() && <Strings name="advisory-teams" height={323} top={-10} />}
      <SectionContainer reversed expanded>
        <AdvisoryReview
          headline={review.headline}
          content={review.content}
          author={review.author}
          imageData={imageData}
        />

        <BlockContainer>
          <ContentBlock headline={block.title} content={block.content} />

          <Row>{block.vector}</Row>
        </BlockContainer>
      </SectionContainer>
    </StringsWrapper>
  );
};

export default SectionTeams;
