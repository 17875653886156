import React from 'react';
import { Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import dataAdvisory from '../data/Advisory.data';
import { SectionDrakkarContainer, SectionDrakkarVector, SectionDrakkarContent } from './SectionDrakkar.styled';

const {
  sectionDrakkar: { content },
} = dataAdvisory;

const SectionDrakkar = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() ? (
        <Strings name="advisory-drakkar--mobile" height={195} top={-40} mobile />
      ) : (
        <Strings name="advisory-drakkar" height={233} top={500} />
      )}
      <SectionDrakkarContainer>
        <SectionDrakkarContent>{content}</SectionDrakkarContent>
        <SectionDrakkarVector />
      </SectionDrakkarContainer>
    </StringsWrapper>
  );
};
export default SectionDrakkar;
