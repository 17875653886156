import styled from 'styled-components';
import { screenMax, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { AdvisoryReview } from '../Advisory.styled';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMax('lg')} {
    padding-left: var(--content-padding-left);
    padding-right: var(--content-padding-right);
  }
`;
const ReviewManagement = styled(AdvisoryReview)`
  ${screenMin('lg')} {
    margin-left: ${toVW(55, 'desktop')};
  }
`;

export { ContentWrapper, ReviewManagement };
