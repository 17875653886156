import React from 'react';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { useStateScreenMobile } from '@helpers/hooks';
import { Strings, StringsWrapper } from '@fragments';

const SectionContactForm = () => (
  <StringsWrapper>
    {useStateScreenMobile() && <Strings name="advisory-form--mobile" height={315} top={55} mobile />}
    <ContactForm headline={useStateScreenMobile() ? '' : <ContactFormHeader>Contact us</ContactFormHeader>} />
  </StringsWrapper>
);

export default SectionContactForm;
