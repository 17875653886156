import styled from 'styled-components';
import { spaceMb, spaceDt, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { Foodsi } from '@logos';
import { CardReview } from './components';

const LogoItem = styled.div`
  &:not(:first-child) {
    margin-left: ${spaceMb(3)};

    ${screenMin('lg')} {
      margin-left: ${spaceDt(11)};
    }
  }
`;

const StyledFoodsi = styled(Foodsi)`
  ${screenMin('lg')} {
    margin-left: auto;
  }
`;

const SectionContainer = styled(Container)`
  margin-bottom: ${spaceMb(5)};

  ${screenMax('lg')} {
    flex-direction: ${(props) => props.reversed && 'column-reverse'};
    padding-left: ${(props) => props.expanded && 0};
    padding-right: ${(props) => props.expanded && 0};
  }

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(23)};
    flex-direction: ${(props) => (props.columned ? 'column' : 'row')};
    padding-left: calc(var(--content-padding-lg-left) + ${spaceDt(12.5)});
  }
`;

const BlockContainer = styled.div`
  ${screenMax('lg')} {
    padding-left: var(--content-padding-left);
    padding-right: var(--content-padding-right);
  }

  ${screenMin('lg')} {
    margin-left: ${toVW(55, 'desktop')};
  }
`;

const AdvisoryReview = styled(CardReview)`
  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(5)};
  }

  ${screenMin('lg')} {
    flex-shrink: 0;
    width: ${toVW(366, 'desktop')};
    margin-top: ${spaceDt(2.5)};
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-top: ${spaceMb(2.5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5.5)};
  }
`;

export { SectionContainer, BlockContainer, AdvisoryReview, StyledFoodsi, Row, LogoItem };
