import styled from 'styled-components';
import { color, spaceDt, spaceMb, screenMin, getTypography, getFontSet } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const CardReviewPicture = styled.div`
  border-radius: 50%;
  width: ${toVW(48, 'mobile')};
  height: ${toVW(48, 'mobile')};
  overflow: hidden;
  margin: 0 ${spaceMb(2)} 0 0;

  ${screenMin('lg')} {
    margin: 0 ${spaceDt(2.5)} 0 0;
    width: ${toVW(72, 'desktop')};
    height: ${toVW(72, 'desktop')};
  }
`;
export const CardReviewContainer = styled.div`
  border: 1px solid ${color.accent[1]};
  padding: ${spaceMb(2)};

  ${screenMin('lg')} {
    background-color: ${color.bg.light};
    border-color: ${color.accent[2]};
    padding: ${spaceDt(3.5)};
  }
`;
export const CardReviewHeadline = styled.p`
  margin: 0;
  ${getFontSet('heading-4', 'mobile')};

  ${screenMin('lg')} {
    ${getFontSet('heading-4', 'mobile', 'desktop')};
  }
`;
export const CardReviewContent = styled.p`
  color: ${color.text.secondary};
  margin: ${spaceMb(3.5)} 0 ${spaceMb(2)} 0;
  ${getFontSet('body-1', 'mobile')};
  ${screenMin('lg')} {
    margin: ${spaceDt(2.5)} 0;
    ${getFontSet('body-1', 'mobile', 'desktop')};
  }
`;
export const CardReviewFooter = styled.div`
  display: flex;
  align-items: center;
  ${getTypography('body-2')};
`;
